<template>
  <div>
    <el-dialog title="修改章节"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="90px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="章节名称"
                          prop="chapter_desc">
              <el-input placeholder="请输入"
                        v-model="form.chapter_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="序号">
              <el-input placeholder="请输入"
                        v-model="form.serial_no"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教材"
                          prop="textbook_id">
              <el-select style="width:100%"
                         v-model="form.textbook_id"
                         :disabled="form.level != 1"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in $parent.bookList"
                           :key="item.textbook_id"
                           :label="item.textbook_name"
                           :value="item.textbook_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="年级"
                          prop="grade_id">
              <el-select style="width:100%"
                         v-model="form.grade_id"
                         :disabled="form.level != 1"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in $parent.gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="星级"
                          prop="chapter_star">
              <el-input-number placeholder="请输入"
                               controls-position="right"
                               :step="1"
                               step-strictly
                               :min="1"
                               :max="5"
                               v-model="form.chapter_star"
                               style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="易错指数"
                          prop="error_prone">
              <el-input-number placeholder="请输入"
                               controls-position="right"
                               :step="1"
                               step-strictly
                               :min="1"
                               :max="5"
                               v-model="form.error_prone"
                               style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        chapter_desc: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        error_prone: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        chapter_star: [
          { required: true, message: '请输入', trigger: 'change' }
        ]
      },
      row: {}
    }
  },

  methods: {
    handleClose () {
      this.dialogVisible = false
      this.form = this.row
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))

        this.$http({
          url: '/api/v2/chapter/edit',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$parent.getTree()
          this.dialogVisible = false
          this.$refs.formRef.resetFields()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
::v-deep .el-input-number.is-controls-right .el-input__inner {
  text-align: left;
}
</style>