<template>
  <div>
    <el-dialog title="知识点"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-row class="row_center">

        <el-cascader v-model="knowledge_ids"
                     :options="knowledgeTree"
                     :show-all-levels="false"
                     style="width:100%"
                     @change="change"
                     :props="{ expandTrigger: 'hover',
                   checkStrictly: true ,
                   multiple:true, 
                   checkStrictly:true,
                   value:'knowledge_id',
                   label:'knowledge_desc',
                   children:'child' }"></el-cascader>
      </el-row>
      <el-row :gutter="12">
        <el-col :span="8"
                style="margin:10px 0;"
                v-for="(item,index) in tags"
                :key="index">
          <el-tag>{{item.knowledge_desc}}</el-tag>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      knowledgeTree: [],
      knowledge_ids: [],
      dialogVisible: false,
      copyList: [],
      tags: [],
      form: {},
      arr: []
    }
  },
  methods: {
    getKnowledgeTree (subject_id) {
      this.$http({
        url: '/api/v2/public/knowledge_tree',
        method: 'get',
        params: {
          subject_id
        }
      }).then(res => {
        console.log()
        this.copyList = {}
        this.knowledgeTree = res.data
        this.flatList(JSON.parse(JSON.stringify(res.data))).forEach(item => {
          this.copyList[item.knowledge_id] = item
        })
        this.getRelationKnowledge()
      })
    },
    getRelationKnowledge (chapter_id) {
      this.$http({
        url: '/api/v2/chapter/get_knowledge',
        method: 'get',
        params: {
          chapter_id: this.form.chapter_id
        }
      }).then(res => {
        let ids = res.data
        this.arr = []
        this.searchParent(ids)
        this.knowledge_ids = this.arr
        this.change()
      })
    },
    searchParent (ids) {
      ids.forEach((item, index) => {
        this.arr[index] = []
        var row = this.copyList[item]
        this.getPar(row, index)

      });
    },
    getPar (row, index) {
      this.arr[index].unshift(row.knowledge_id)
      if (row.knowledge_parent_id) {
        var item = this.copyList[row.knowledge_parent_id]
        this.getPar(item, index)
      }
    },
    save () {
      let knowledge_ids = this.tags.map(item => {
        return item.knowledge_id
      })
      let form = JSON.parse(JSON.stringify(this.form))
      form.knowledge_ids = knowledge_ids
      this.$http({
        url: '/api/v2/chapter/relation',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.$parent.getTree()
        this.handleClose()
        // this.$parent.search()
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    change () {
      this.tags = this.knowledge_ids.map(item => {
        return {
          knowledge_desc: this.copyList[item[item.length - 1]].knowledge_desc,
          knowledge_id: item[item.length - 1],
        }
      })
    },
    flatList (list) {
      let allList = []
      for (let i = 0, len = list.length; i < len; i++) {
        allList.push(list[i])
        if (list[i].child && list[i].child.length > 0) {
          allList = allList.concat(this.flatList(list[i].child))
        }
      }
      return allList
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog {
  border-radius: 15px;
}

::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.row_center {
  display: flex;
  justify-content: center;
  height: 36px;
  overflow: hidden;
}
::v-deep .el-cascader__tags {
  height: 36px;
  overflow: hidden;
  margin-top: 6px;
}
::v-deep .el-tag__close {
  display: none;
}
::v-deep .el-input__inner {
  height: 36px !important;
}
</style>