<template>
  <div>
    <el-form label-width="60px"
             :model="form">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       @change="getBookList"
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="教材:">
            <el-select style="width:100%"
                       v-model="form.book_id"
                       clearable
                       filterable
                       @change="getTree"
                       placeholder="请选择">
              <el-option v-for="item in bookList"
                         :key="item.textbook_id"
                         :label="item.textbook_name"
                         :value="item.textbook_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="上级章节"
                        label-width="80px">
            <el-cascader v-model="form.parent_id"
                         :options="tree"
                         style="width:100%"
                         filterable
                         :show-all-levels="false"
                         :props="{checkStrictly: true,value:'chapter_id',
                           label:'chapter_desc',children:'child'}"
                         clearable></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12"
                style="margin-bottom:10px">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="add">新增</el-button>
          <el-button type="primary"
                     @click="importChapter">导入章节</el-button>
          <el-button type="primary"
                     @click="autoEdit">自动同步</el-button>
        </el-col>
      </el-row>
    </el-form>
    <TreeTable :stripe="true"
               :border="true"
               :data="tableData"
               v-if="tableData.length"
               :tree-type="true"
               :columns="tableColumns"
               :children-prop="config.children"
               :expand-type="false"
               :show-row-hover="true"
               :selection-type="false">

      <template slot="subject_name"
                slot-scope="{row}">
        <span @click="showSelect(row,'showSubject','subject_id')"
              class="block">
          <div class="line_edit_border">
            {{row.subject_name}}
          </div>
        </span>
      </template>

      <template slot="textBook"
                slot-scope="{row}">
        {{row.textbook_name}}

      </template>

      <!-- 年级 -->
      <template slot="grade"
                slot-scope="{row}">
        {{row.grade_name}}

      </template>

      <!-- 星级 -->
      <template slot="star"
                slot-scope="{row}">
        {{row.chapter_star}}
      </template>

      <template slot="opt"
                slot-scope="{row}">
        <el-tooltip effect="dark"
                    content="修改"
                    placement="top-start">
          <img :src="imgs['edit']"
               @click="editRow(row)"
               class="operyion_img">
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="关联知识点"
                    placement="top-start">
          <img :src="imgs['relation']"
               @click="relationKnowledge(row)"
               class="operyion_img">
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="删除"
                    placement="top-start">
          <img :src="imgs['del']"
               @click="del(row)"
               class="operyion_img">
        </el-tooltip>
      </template>
    </TreeTable>

    <Form ref="FormRef" />
    <KnowledgeForm ref="KnowledgeFormRef" />
    <EditForm ref="EditFormRef" />
    <!-- <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" /> -->
  </div>
</template>

<script>
import Form from './components/form.vue'
import KnowledgeForm from './components/knowledgeForm.vue'
import EditForm from './components/editForm.vue'
export default {
  data () {
    return {
      imgs: {
        del: require('@/assets/icons/icon-删除@2x.png'),
        edit: require('@/assets/icons/icon-修改@2x.png'),
        reset: require('@/assets/icons/icon-重置密码.png'),
        look: require('@/assets/icons/icon-查看@2x.png'),
        fabu: require('@/assets/icons/发布.png'),
        relation: require('@/assets/icons/icon-关联知识点@2x.png'),
      },
      tableColumns: [
        { prop: 'chapter_desc', align: 'left', label: '章节', width: '400', },
        { prop: 'subject_name', align: 'left', label: '科目', width: '', },
        { prop: 'textbook_name', align: 'left', label: '所属教材', width: '', },
        { prop: 'grade_name', align: 'left', label: '年级', width: '', type: 'template', template: 'grade' },
        { prop: 'knowledge_desc', align: 'left', label: '知识点', width: '', },
        { prop: 'chapter_star', align: 'left', label: '星级', width: '', type: 'template', template: 'star' },
        { prop: 'chapter_frequency', align: 'left', label: '频率', width: '', },
        { prop: 'error_prone', align: 'left', label: '易错指数', width: '', },
        {
          prop: 'opt', align: 'center', label: '操作', width: '180', type: 'template',
          template: 'opt', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '关联知识点', type: 'edit', event: 'relationKnowledge' },
            { content: '删除', type: 'del', event: 'del' },
          ]
        },
      ],
      subjectList: [],
      tableData: [],
      bookList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {
        subject_id: '',
        book_id: '',
        parent_id: []
      },
      pageName: 'slcz_chapter_admin',
      config: { hasChildren: 'child', children: 'child' },
      line_model: '',
      gradeList: [],
      tree: []
    }
  },
  components: {
    Form, KnowledgeForm, EditForm
  },
  mounted () {
    this.getSubjectList()
  },
  methods: {
    getTree () {
      this.search()
      this.form.parent_id = ''
      if (this.form.subject_id == '' && this.form.book_id == '') {
        this.tree = []
        return
      }
      this.$http({
        url: '/api/v2/public/get_chapter_tree',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          book_id: this.form.book_id
        }
      }).then(res => {
        this.tree = res.data
      })
    },
    autoEdit () {
      this.$http({
        url: '/api/v2/chapter/auto_relation',
        method: 'post'
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '同步成功!',
          type: 'success'
        })
      })
    },
    // 导入章节
    importChapter () {
      let file
      this.$get_file({ limit: 1000, fileType: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }).then(res => {
        file = res
        let formData = new FormData()
        formData.append('file', file)
        formData.append('sign', this.$getMd5Form(file))
        this.$http({
          url: '/api/v2/chapter/import ',
          method: 'post',
          data: formData
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.search()
        })
      })

    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/chapter/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
        this.$http({
          url: '/api/v2/public/grade_lst',
          method: 'get'
        }).then(res => {
          this.gradeList = res.data.list
        })
      })
    },
    initData () {
      // console.log(this.form.parent_id.length)
      // return
      let params = {
        subject_id: this.form.subject_id,
        book_id: this.form.book_id,
        parent_id: this.form.parent_id.length ? this.form.parent_id[this.form.parent_id.length - 1] : '',
      }
      if (!params.subject_id) {
        return this.$message.warning('请选择科目')
      }
      this.$http({
        url: '/api/v2/chapter/lst',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.initData()
    },
    editRow (row) {
      let form = {
        chapter_id: row.chapter_id,
        serial_no: row.serial_no,
        chapter_desc: row.chapter_desc,
        chapter_star: row.chapter_star,
        textbook_id: row.textbook_id,
        grade_id: row.grade_id,
        level: row.level,
        error_prone: row.error_prone
      }
      this.$refs.EditFormRef.dialogVisible = true
      this.$refs.EditFormRef.level = row.level
      this.$refs.EditFormRef.form = form

      this.$refs.EditFormRef.text = '修改'
    },
    getBookList () {
      this.form.book_id = ''
      this.getTree()
      this.$http({
        url: '/api/v2/public/getBookBySubject',
        method: 'get',
        params: {
          subject_id: this.form.subject_id
        }
      }).then(res => {
        this.bookList = res.data.list
      })

    },
    add () {
      let form = {
        chapter_parent_id: 0,
        serial_no: '',
        chapter_desc: '',
        subject_id: '',
        chapter_star: '',
        textbook_id: '',
        grade_id: '',
        error_prone: 3
      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getList()
    },
    del (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/chapter/del',
          method: 'get',
          params: {
            chapter_id: row.chapter_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    relationKnowledge (row) {
      let form = {
        subject_id: row.subject_id,
        chapter_id: row.chapter_id
      }
      this.$refs.KnowledgeFormRef.form = form
      this.$refs.KnowledgeFormRef.getKnowledgeTree(row.subject_id, row.chapter_id)
      this.$refs.KnowledgeFormRef.dialogVisible = true
    },
    // getgrade () {
    //   this.$http({
    //     url: '/api/v2/chapter/editGrade',
    //     method: 'post',
    //     data: {
    //       chapter_id: 1,
    //       grade_id: 1
    //     }
    //   }).then(res => {
    //     this.$notify({
    //       title: '提示',
    //       message: '修改成功!',
    //       type: 'success'
    //     });
    //     this.search()
    //   })
    // },
    // d第一个参数 是行内数据 第二个参数 显示类型  第三个是赋值类型
    showSelect (row, type, prop) {
      if (row.level != 1) return
      let list = this.tableData.map(item => {
        item.showGrade = false
        item.showBook = false
        item.showSubject = false
        item.showStar = false
        if (item.chapter_id == row.chapter_id) {
          item[type] = true
          this.line_model = item[prop]
        }
        return item
      })
      this.$set(this, 'tableData', list)
    },
    save (row, type, showType) {
      let form = {
        chapter_id: row.chapter_id,
      }
      form[type] = this.line_model
      this.$http({
        url: '/api/v2/chapter/editOther',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        row[showType] = false
        this.search()
      })
    },
    submit (row) {
      let form = {
        chapter_id: row.chapter_id,
        serial_no: row.serial_no,
        chapter_desc: row.chapter_desc,
        chapter_star: this.line_model
      }
      this.$http({
        url: '/api/v2/chapter/edit',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.search()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.line {
  display: flex;
  .el-button {
    margin-left: 5px;
  }
}
.block {
  display: flex;
}
.line_edit_border {
  // border: 0.5px solid #ccc;
  // width: 100%;
}
</style>